<template>
  <div class="Home">
    <v-container>
      <div>
        <v-row>
          <v-col>
            <h1>Home</h1>
          </v-col>
        </v-row>
       <v-row>
         <v-col>
           <h1>
             Logged In: {{isLoggedIn}}
           </h1>
         </v-col>
       </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>

  import {mapGetters} from "vuex";

  export default {
    name: 'Home',
    components: {
    },
    data: function(){
      return {}
    },
    created: function(){
    },
    computed: {
      ...mapGetters({
        isLoggedIn: 'isLoggedIn'
      })
    }
  }
</script>
