import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import axios from "axios";
import store from "@/store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login'),
    props: true
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register')
  },
  {
    path: '/trips',
    name: 'Trips',
    component: () => import('../views/Trips'),
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)){
    if (!store.getters.isLoggedIn){
      next({name: 'Login'})
    } else {
      const currentUser = store.getters.getCurrentUser
      const loginRes = await checkLogin(currentUser)
      if (loginRes){
        next()
      }else{
        next({name: 'Login'})
        await store.dispatch('logoutUser')
      }
    }
  }else{
    next()
  }
})

async function checkLogin(currentUser){
  let result;
  await axios.get(Vue.prototype.$baseURL + '/testToken/', {
    headers: {
      'Authorization': 'Token ' + currentUser['token']
    }
  }).then(response => {
    result = response.status === 200
    return response.status === 200
  })

  return result
}

export default router
