import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentUser: JSON.parse(window.localStorage.getItem('currentUser'))
  },
  mutations: {
    SET_USER(state, userObj){
      state.currentUser = userObj
      window.localStorage.setItem('currentUser', JSON.stringify(userObj))
    },
    LOGOUT_USER(state){
      state.currentUser = {}
      window.localStorage.setItem('currentUser', JSON.stringify({}))
    }
  },
  actions: {
    loadUser: function(state){
      state.commit('SET_USER', JSON.parse(window.localStorage.getItem('currentUser')))
    },
    logoutUser: function (state){
      state.commit('LOGOUT_USER')
    },
    loginUser: function(state, userObj){
      state.commit('SET_USER', userObj)
    }
  },
  getters: {
    getCurrentUser: function(state){
      return state.currentUser
    },
    isLoggedIn: function(state, getters){
      const user = getters.getCurrentUser
      if (user === null){
        return false
      }
      if ('token' in user && user['token'] !== ""){
        return true
      }
      return false
    }
  },
  modules: {
  }
})
